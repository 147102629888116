import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Article } from '../_models/article.model';
import { CartService } from '../../cart/_services/cart.service';
import { Description, DescriptionStrategy, Image } from '@ks89/angular-modal-gallery';
import { ArticlesService } from '../_services/articles.service';

@Component({
    selector: 'app-article',
    templateUrl: './article.component.html',
    styleUrls: ['./article.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ArticleComponent implements OnInit {
    @Input('article') article: Article;
    quantity: any = 1;
    image: Image;
    imageDescription: Description;

    constructor(private $cart: CartService, private $articles: ArticlesService) {


    }

    ngOnInit() {
        this.image = new Image(this.article.id, {img: this.article.image, title: this.article.name});
        this.imageDescription = {
            strategy: DescriptionStrategy.ALWAYS_HIDDEN
        };
    }

    addToCart() {
        this.quantity = parseFloat(this.quantity);
        this.$cart.addArticle(this.article, this.quantity).then((stock) => {
            this.article.stock = stock;
        }).catch(() => {
        });
    }

    subscribeNotification(event) {
        this.$articles.updateStockNotifications(this.article.id, event.checked);
    }

    maxQuantity() {
        return this.article.stock >= 999 ? 999 : this.article.stock;
    }

    additionDisabled() {
        const quantity = parseFloat(this.quantity) || 0;
        return (quantity < 1 || quantity > this.article.stock) || this.$cart.loading;
    }
}
