<form [formGroup]="form" (submit)="onSubmit()" [ngClass]="{'-submitted': submitted}" class="form login-form">
    <div class="form-group mb-2">
        <label for="username">{{'username' | translate}}:</label>
        <input formControlName="username"
               class="input -wide"
               id="username"
               type="text">
    </div>
    <div class="form-group mb-2">
        <label for="password">{{'password' | translate}}:</label>
        <input formControlName="password"
               class="input -wide"
               id="password"
               type="password">
    </div>
    <p *ngIf="!form.valid && submitted" class="error-text">
        {{'errors.required' | translate}}
    </p>
    <p *ngIf="error?.status === 401" class="error-text">
        {{'errors.bad_credentials' | translate}}
    </p>
    <p *ngIf="error && error.status !== 401" class="error-text">
        {{'errors.server_communication' | translate}}
    </p>
    <button type="submit" class="mt-1 btn-primary -wide">
        {{'login' | translate}}
    </button>
</form>
