import { Component, OnInit } from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CheckoutService } from "../_services/checkout.service";
import { ArticlesService } from "../../articles/_services/articles.service";
import { CartService } from "../../cart/_services/cart.service";
import { ApiService } from "../../core/_services/api.service";
import { NotificationService } from "../../core/_services/notification.service";
import { AuthService } from "../../auth/_services/auth.service";
import { PoNumber } from "../_models/po-number";
import { Justification } from "../_models/justification";
import { LanguagesService } from "../../languages/_services/languages.service";
import { User } from "../../auth/_models/user";

@Component({
    selector: "app-checkout-form",
    templateUrl: "./checkout-form.component.html",
    styleUrls: ["checkout-form.component.scss"],
})
export class CheckoutFormComponent implements OnInit {
    form: FormGroup;
    submitted = false;
    loading = false;
    user: User;
    poNumbers: PoNumber[] = [];
    justifications: Justification[] = [];
    termsConditionsText: string;
    hasOrderLimitCodeField = false;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private $api: ApiService,
        private $articles: ArticlesService,
        private $auth: AuthService,
        private $cart: CartService,
        private $notify: NotificationService,
        private $checkout: CheckoutService,
        public $languages: LanguagesService
    ) {
        this.termsConditionsText = this.route.snapshot.data.text;
        this.$languages.onChange.subscribe(() => {
            this.setJustifications();
            this.setTermsConditionsText();
        });
    }

    ngOnInit() {
        this.user = this.$auth.user;
        this.initForm();
        this.setPoNumbers();
        this.setJustifications();
        this.setOrderLimitCode();
    }

    initForm() {
        this.form = this.fb.group({
            deliveryCompanyName: [this.user.companyName, []],
            deliveryDepartmentName: [this.user.departmentName, []],
            poNumber: ["", Validators.required],
            orderJustification: ["", Validators.required],
            deliveryInitials: [this.user.initials, Validators.required],
            deliveryMiddleName: [this.user.middleName],
            deliveryLastName: [this.user.lastName, Validators.required],
            deliveryEmail: [
                this.user.email,
                [Validators.required, this.validateEmail()],
            ],
            deliveryPhoneNumber: [this.user.phoneNumber, Validators.required],
            deliveryPostCode: [this.user.postCode, Validators.required],
            deliveryHouseNumber: [this.user.houseNumber, Validators.required],
            deliveryHouseNumberAddition: [this.user.houseNumberAddition],
            deliveryStreet: [this.user.street, Validators.required],
            deliveryCity: [this.user.city, Validators.required],
            deliveryCountry: [null, Validators.required],
            terms: [false, Validators.pattern("true")],
            hcpFirstName: [""],
            hcpMiddleName: [""],
            hcpLastName: [""],
        });
    }

    setPoNumbers() {
        this.poNumbers = this.$auth.user.poNumbers;
        if (this.poNumbers.length === 1) {
            this.form
                .get("poNumber")
                .setValue(`/api/po_numbers/${this.poNumbers[0].id}`);
        }
    }

    setJustifications() {
        this.$api.get("api/order_justifications").then((data: any[]) => {
            this.justifications = data.map((item) => new Justification(item));
        });
    }

    setTermsConditionsText() {
        this.$api.get(`api/texts`).then((texts: any) => {
            this.termsConditionsText = texts.find(
                (text) => text.code === "terms"
            ).content;
        });
    }

    setOrderLimitCode() {
        if (this.$cart.hasArticlesApplicableForOrderLimit()) {
            this.hasOrderLimitCodeField = true;
            this.form.addControl(
                "orderLimitCode",
                new FormControl("", Validators.required)
            );
        }
    }

    validateEmail() {
        return Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }

    onSubmit() {
        this.submitted = true;
        if (this.form.valid) {
            this.loading = true;
            this.$api
                .post("api/orders", this.form.value)
                .then((data) => {
                    this.$cart.clearCard();
                    this.$articles.articles = [];
                    this.$checkout.success = true;
                    this.router.navigate(["../"]);
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    if (
                        e.status &&
                        e.status === 422 &&
                        e.body &&
                        e.body.length > 0
                    ) {
                        e.body.map((error) => {
                            this.$notify.error(error.message, null, {
                                disableTimeOut: true,
                            });
                        });
                    } else {
                        this.$notify.serverError();
                    }
                });
        }
    }

    private getAddress(
        postcode: string,
        houseNumber: string,
        houseNumberAddition: string | null = null
    ) {
        this.loading = true;
        this.$api
            .get(`api/orders/get_address`, {
                postcode,
                houseNumber,
                houseNumberAddition,
            })
            .then((response: any) => {
                if (response && response.street && response.city) {
                    this.form.get("deliveryStreet").setValue(response.street);
                    this.form.get("deliveryCity").setValue(response.city);
                }
                this.loading = false;
            })
            .catch((e) => {
                this.loading = false;
                console.error(e);
            });
    }

    onUpdateAddress() {
        const postcode = this.form.get("deliveryPostCode") as FormControl;
        const houseNumber = this.form.get("deliveryHouseNumber") as FormControl;
        const houseNumberAddition = this.form.get(
            "deliveryHouseNumberAddition"
        ) as FormControl;

        if (postcode.valid && houseNumber.valid) {
            let data = {
                postcode: postcode.value,
                houseNumber: houseNumber.value,
                houseNumberAddition: null,
            };
            if (houseNumberAddition.value.length > 0) {
                data.houseNumberAddition = houseNumberAddition.value;
            }
            this.getAddress(
                data.postcode,
                data.houseNumber,
                data.houseNumberAddition
            );
        }
    }
}
