import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CartService } from '../../cart/_services/cart.service';

@Injectable()
export class CheckoutGuard implements CanActivate {
    constructor(private router: Router, private $cart: CartService) {
    }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.$cart.getCart().then(() => {
            if (this.$cart.items.length && !this.$cart.hasError()) {
                return true;
            }
            this.router.navigate(['/']);
            return false;
        }).catch(e => {
            this.router.navigate(['/']);
            return false;
        });
    }
}
