<div class="row">
    <div class="col-5 col-lg-12">
        <aside class="sidebar">
            <div class="sidebar-inner">
                <h2 class="h2 -primary mb-1">{{'shipping_information' | translate}}</h2>
                <img src="assets/img/delivery-box.svg" alt="">
            </div>
            <app-cart></app-cart>
        </aside>
    </div>
    <div class="col-7 col-lg-12">
        <main class="main">
            <app-checkout-form>
            </app-checkout-form>
        </main>
    </div>
</div>
