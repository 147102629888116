export class ArticleGroup {
    id: number;
    code: string;
    name: string;

    constructor(articleGroup: any) {
        this.id = articleGroup.id;
        this.code = articleGroup.code;
        this.name = articleGroup.name;
    }
}