<h2 class="h2 mb-1 -primary">
    {{'shipping_address_details' | translate}}
</h2>
<form [formGroup]="form" [ngClass]="{'-submitted': submitted}" (submit)="onSubmit()" class="form checkout-form">
    <div class="row mb-1 form-group">
        <div class="col-4 col-xs-12 v-center">
            <label for="company">{{'company' | translate}}</label>
        </div>
        <div class="col-8 col-xs-12">
            <input id="company" formControlName="deliveryCompanyName" maxlength="30" type="text" class="input -wide">
        </div>
    </div>
    <div class="row mb-1 form-group">
        <div class="col-4 col-xs-12 v-center">
            <label for="department">{{'department' | translate}}</label>
        </div>
        <div class="col-8 col-xs-12">
            <input id="department" formControlName="deliveryDepartmentName" maxlength="30" type="text" class="input -wide">
        </div>
    </div>
    <div class="row mb-1 form-group">
        <div class="col-4 v-center">
            <label for="po-number">{{'po_number' | translate}} *</label>
        </div>
        <div class="col-8 col-xs-12 flex-box">
            <mat-select class="select"
                        id="po-number"
                        [disabled]="poNumbers.length === 0"
                        [formControlName]="'poNumber'"
                        [placeholder]="(poNumbers.length === 0 ? 'no_selection_contact_admin' : 'no_selection') | translate">
                <mat-option [value]="'/api/po_numbers/' + number.id" *ngFor="let number of poNumbers">
                    {{number.content}}
                </mat-option>
            </mat-select>
        </div>
    </div>
    <div class="row mb-1 form-group">
        <div class="col-4 v-center">
            <label for="po-number">{{'justification' | translate}} *</label>
        </div>
        <div class="col-8 col-xs-12 flex-box">
            <mat-select class="select"
                        id="justification"
                        [disabled]="justifications.length === 0"
                        [formControlName]="'orderJustification'"
                        [placeholder]="'no_selection' | translate">
                <mat-option [value]="'/api/order_justifications/' + justification.id" *ngFor="let justification of justifications">
                    {{justification.content}}
                </mat-option>
            </mat-select>
        </div>
    </div>
    <div class="row mb-1 form-group">
        <div class="col-4 col-xs-12">
            <div class="label-group">
                <label for="firstName">{{'initials' | translate}};</label>
                <label for="middleName">{{'middle_name' | translate}};</label>
                <label for="lastName">{{'last_name' | translate}}*</label>
            </div>
        </div>
        <div class="col-2 col-xs-3">
            <input id="firstName" formControlName="deliveryInitials" type="text" class="input -wide">
        </div>
        <div class="col-2 col-xs-3">
            <input id="middleName" formControlName="deliveryMiddleName" type="text" class="input -wide">
        </div>
        <div class="col-4 col-xs-6">
            <input id="lastName" formControlName="deliveryLastName" type="text" class="input -wide">
        </div>
    </div>
    <div class="row mb-1 form-group">
        <div class="col-4 col-xs-12 v-center">
            <label for="email">{{'email' | translate}} *</label>
        </div>
        <div class="col-8 col-xs-12">
            <input id="email" formControlName="deliveryEmail" type="email" class="input -wide">
        </div>
    </div>
    <div class="row mb-1 form-group">
        <div class="col-4 col-xs-12 v-center">
            <label for="phone">{{'phone_number' | translate}} *</label>
        </div>

        <div class="col-8 col-xs-12">
            <input id="phone" formControlName="deliveryPhoneNumber" type="text" class="input -wide">
        </div>
    </div>
    <div class="row mb-1 form-group">
        <div class="col-4 col-xs-12 v-center">
            <label for="postcode">{{'postal_code' | translate}}*</label>
        </div>
        <div class="col-2 col-xs-12">
            <input id="postcode" (blur)="onUpdateAddress()" maxlength="30" formControlName="deliveryPostCode" type="text" class="input -wide">
        </div>
        <div class="col-3 col-xs-6 form-group_child">
            <div class="row">
                <div class="col-6 col-xs-12 v-center">
                    <label for="houseNumber">{{'house_number' | translate}}*</label>
                </div>
                <div class="col-6 col-xs-12">
                    <input id="houseNumber" (blur)="onUpdateAddress()" maxlength="30" formControlName="deliveryHouseNumber" type="text" class="input -wide">
                </div>
            </div>
        </div>
        <div class="col-3 col-xs-6 form-group_child">
            <div class="row">
                <div class="col-6 col-xs-12 v-center">
                    <label for="aptNumber">{{'apt_number' | translate}}</label>
                </div>
                <div class="col-6 col-xs-12">
                    <input id="aptNumber" (blur)="onUpdateAddress()" maxlength="30" formControlName="deliveryHouseNumberAddition" type="text" class="input -wide">
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-1 form-group">
        <div class="col-4 col-xs-12 v-center">
            <label for="street">{{'street' | translate}} *</label>
        </div>
        <div class="col-8 col-xs-12">
            <input id="street" maxlength="30" formControlName="deliveryStreet" type="email" class="input -wide">
        </div>
    </div>
    <div class="row mb-1 form-group">
        <div class="col-4 col-xs-12 v-center">
            <label for="city">{{'city' | translate}}*</label>
        </div>
        <div class="col-8 col-xs-12">
            <input id="city" maxlength="30" formControlName="deliveryCity" type="email" class="input -wide">
        </div>
    </div>
    <div class="row mb-1 form-group">
        <div class="col-4 v-center">
            <label for="country">{{'country' | translate}} *</label>
        </div>
        <div class="col-8 col-xs-12 flex-box">
            <mat-select class="select"
                        id="country"
                        [formControlName]="'deliveryCountry'"
                        [placeholder]="'no_selection' | translate">
                <mat-option value="NL">
                    {{'countries.nl' | translate}}
                </mat-option>
                <mat-option value="BE">
                    {{'countries.be' | translate}}
                </mat-option>
                <mat-option value="LU">
                    {{'countries.lu' | translate}}
                </mat-option>
            </mat-select>
        </div>
    </div>
    <div class="row mb-1 form-group">
        <div class="col-4 col-xs-12">
            <div class="label-group">
                <label>HCP:</label>
                <label for="hcpFirstName"> {{'initials' | translate}};</label>
                <label for="hcpMiddleName">{{'middle_name' | translate}};</label>
                <label for="hcpLastName">{{'last_name' | translate}}</label>
            </div>
        </div>
        <div class="col-2 col-xs-3">
            <input id="hcpFirstName" formControlName="hcpFirstName" type="text" class="input -wide">
        </div>
        <div class="col-2 col-xs-3">
            <input id="hcpMiddleName" formControlName="hcpMiddleName" type="text" class="input -wide">
        </div>
        <div class="col-4 col-xs-6">
            <input id="hcpLastName" formControlName="hcpLastName" type="text" class="input -wide">
        </div>
    </div>
    <div *ngIf="hasOrderLimitCodeField" class="row mb-1 form-group">
        <div class="col-4 col-xs-12 v-center">
            <label for="orderLimitCode">{{'order_limit_code' | translate}} *</label>
        </div>
        <div class="col-8 col-xs-12">
            <input id="orderLimitCode" formControlName="orderLimitCode" type="text" class="input -wide">
        </div>
    </div>
    <div class="row mb-1">
        <div class="col-12">
            <p *ngIf="!submitted">*{{'required_fields' | translate}}</p>
            <p class="error-text" *ngIf="submitted && !form.valid">{{'errors.required' | translate}}</p>
        </div>
    </div>
    <div class="row mb-1 form-group" *ngIf="termsConditionsText">
        <div class="col-4 v-center">
        </div>
        <div class="col-8 col-xs-12 flex-box">
            <mat-checkbox [formControlName]="'terms'">
                {{termsConditionsText}}
            </mat-checkbox>
        </div>
    </div>
    <div class="row mb-1">
        <div class="col-8 col-xs-12 offset-4">
            <button class="btn-primary" type="submit" [disabled]="loading">
                {{'buttons.send' | translate}}
            </button>
        </div>
    </div>
</form>
