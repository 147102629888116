import { Injectable } from '@angular/core';

@Injectable()
export class CheckoutService {
    _success = false;

    constructor(){
    }

    set success(state){
        this._success = state;
    }

    get success(){
        if (!this._success) {
            return false;
        }
        this._success = false;
        return true;
    }
}
