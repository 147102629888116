import { CartItem } from './cart-item.model';


export class Cart {
    items: {
        article: number,
        quantity: number
    }[] = [];

    constructor(items: CartItem[]) {
        this.items = items ? items.map((item: CartItem) => {
            return {
                article: item.article.id,
                quantity: item.quantity
            };
        }) : [];
    }
}