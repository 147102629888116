export class ArticleType {
    id: number;
    code: string;
    isOrderLimitApplicable: boolean;

    constructor(articleType: any) {
        this.id = articleType.id;
        this.code = articleType.code;
        this.isOrderLimitApplicable = articleType.isOrderLimitApplicable;
    }
}
