import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TitleService {
    titleChanged: BehaviorSubject<string> = new BehaviorSubject('Webshop');

    constructor(){
    }
}
