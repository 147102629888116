import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartComponent } from './cart.component';
import { CartItemComponent } from './cart-item/cart-item.component';
import { CartService } from './_services/cart.service';
import { LanguagesModule } from '../languages/languages.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        LanguagesModule,
        SharedModule
    ],
    declarations: [
        CartComponent,
        CartItemComponent
    ],
    exports: [
        CartComponent
    ],
    providers: [
        CartService
    ]
})
export class CartModule {
}
