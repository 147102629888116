import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutComponent } from './checkout.component';
import { CartModule } from '../cart/cart.module';
import { CheckoutFormComponent } from './checkout-form/checkout-form.component';
import { CheckoutGuard } from './_services/checkout.guard';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { CheckoutService } from './_services/checkout.service';
import { LanguagesModule } from '../languages/languages.module';
import { TermsConditionsResolver } from './_services/terms-conditions.resolver';


@NgModule({
    imports: [
        CommonModule,
        CartModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatCheckboxModule,
        LanguagesModule
    ],
    declarations: [
        CheckoutComponent,
        CheckoutFormComponent
    ],
    providers: [
        CheckoutGuard,
        CheckoutService,
        TermsConditionsResolver
    ]
})
export class CheckoutModule {
}
