import { ArticleGroup } from "./article-group.model";
import { ArticleType } from "./article-type.model";

export class Article {
    id: number;
    name: string;
    code: string;
    stock: number;
    responsibilityCenter: string;
    stockStatus: string;
    fullCode: string;
    articleGroup: ArticleGroup;
    articleType: ArticleType;
    image: string;
    thumbnail: string;
    packingUnit: string;
    stockNotification = false;

    constructor(article: any) {
        this.id = article.id;
        this.name = article.name;
        this.code = article.code;
        this.stock = article.stock;
        this.responsibilityCenter = article.responsibilityCenter;
        this.stockStatus = article.stockStatus;
        this.fullCode = article.fullCode;
        this.image = article.originalImageUrl;
        this.thumbnail = article.thumbnailImageUrl;
        this.packingUnit = article.packingUnit;
        this.articleGroup = article.articleGroup
            ? new ArticleGroup(article.articleGroup)
            : null;
        this.articleType = article.articleType
            ? new ArticleType(article.articleType)
            : null;
    }
}
