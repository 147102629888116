import { Component, Input } from '@angular/core';
import { CartItem } from '../_models/cart-item.model';
import { CartService } from '../_services/cart.service';

@Component({
    selector: 'app-cart-item',
    templateUrl: './cart-item.component.html',
    styleUrls: ['./cart-item.component.scss']
})
export class CartItemComponent {
    @Input('item') item: CartItem;

    constructor(public $cart: CartService) {


    }

    remove() {
        this.$cart.removeItem(this.item);
    }
}
