import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-toggle-btn',
    templateUrl: './toggle-btn.component.html',
    styleUrls: ['./toggle-btn.component.scss']
})
export class ToggleBtnComponent implements OnInit {
    @Output() onExpand = new EventEmitter<boolean>();
    expanded = true;

    constructor() {
    }

    ngOnInit() {
    }

    toggle() {
        this.expanded = !this.expanded;
        this.onExpand.next(this.expanded);
    }
}
