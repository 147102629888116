import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    form: FormGroup;
    submitted = false;
    error = null;

    constructor(private fb: FormBuilder,
                private router: Router,
                private $auth: AuthService) {
        this.initForm();
    }

    ngOnInit() {
    }

    initForm() {
        // testcustomer
        // Ch4ng3m3!N0w
        this.form = this.fb.group({
            username: this.fb.control('', Validators.required),
            password: this.fb.control('', Validators.required)
        });

        this.form.valueChanges.subscribe(() => {
            this.error = null;
        });
    }

    onSubmit() {
        this.submitted = true;
        if (this.form.valid) {
            this.$auth.login(this.form.value).then((data: any) => {
                this.router.navigate(['/']);
            }).catch(err => {
                this.error = err;
            });
        }
    }
}
