import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { DemoComponent } from './demo/demo.component';
import { FooterComponent } from './footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from '../auth/auth.module';
import { ArticlesModule } from '../articles/articles.module';
import { SearchModule } from '../search/search.module';
import { CheckoutModule } from '../checkout/checkout.module';
import { LanguagesModule } from '../languages/languages.module';
import { TitleService } from './_services/title.service';
import { ApiService } from './_services/api.service';
import { ToastrModule } from 'ngx-toastr';
import { NotificationService } from './_services/notification.service';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        LanguagesModule,
        BrowserAnimationsModule,
        AuthModule,
        SearchModule,
        ArticlesModule,
        CheckoutModule,
        ToastrModule.forRoot({
            enableHtml: true
        })
    ],
    declarations: [
        HeaderComponent,
        DemoComponent,
        FooterComponent
    ],
    exports: [
        HeaderComponent,
        DemoComponent,
        FooterComponent
    ],
    providers: [
        TitleService,
        ApiService,
        NotificationService
    ]
})

export class CoreModule {
}
