import { Component } from '@angular/core';
import { CartService } from './_services/cart.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss']
})
export class CartComponent {
    expanded = true;

    constructor(public $cart: CartService, private router: Router, private route: ActivatedRoute) {
    }

    goToCheckout() {
        this.router.navigate(['checkout']);
    }

    goBack() {
        this.router.navigate(['../']);
    }

    isCheckoutDisabled() {
        return this.$cart.hasError();
    }
}
