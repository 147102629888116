import { Component, OnInit } from '@angular/core';
import { TitleService } from '../_services/title.service';
import { AuthService } from '../../auth/_services/auth.service';
import { User } from '../../auth/_models/user';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    submenuOpened = false;
    authenticated = false;
    title: string;
    user: User;

    constructor(private $title: TitleService, private $auth: AuthService) {
        this.setAuthData();
        this.title = $title.titleChanged.value;
    }

    ngOnInit() {
        this.$auth.authenticated.subscribe(() => {
            this.setAuthData();
        });
    }

    setAuthData() {
        this.authenticated = this.$auth.authenticated.value;
        this.user = this.$auth.user;
    }

    toggleSubmenu() {
        this.submenuOpened = !this.submenuOpened;
    }

    logout() {
        this.$auth.logout();
        this.submenuOpened = false;
    }
}
