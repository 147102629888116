import { Component } from "@angular/core";
import { ArticlesService } from "../articles/_services/articles.service";
import { ArticleGroup } from "../articles/_models/article-group.model";

@Component({
    selector: "app-search",
    templateUrl: "./search.component.html",
    styleUrls: ["./search.component.scss"],
})
export class SearchComponent {
    articleGroups: ArticleGroup[] = [];
    groupQuery: string;
    codeQuery: string;
    nameQuery: string;
    expanded = true;

    constructor(private $articles: ArticlesService) {
        this.setArticlesGroup();
    }

    setArticlesGroup() {
        this.$articles
            .getArticleGroups()
            .then((articleGroups: ArticleGroup[]) => {
                this.articleGroups = articleGroups.sort((a, b) =>
                    a.name.localeCompare(b.name)
                );
            });
    }

    search(param, query) {
        this.$articles.setArticles({ [param]: query });
    }

    reset(queries: string[]) {
        queries.forEach((value) => {
            this[`${value}Query`] = null;
        });
    }
}
