import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[appNumber]'
})
export class NumberDirective {
    @Input('maxLength') maxLength = Infinity;
    @Input('decimal') decimal = 2;
    @Input('max') max: number;
    @Input('correction') correction = false;
    value: any = 0;

    constructor(private input: ElementRef) {
        this.value = this.input.nativeElement.value;
    }

    @HostListener('keydown', ['$event']) onKeydown(e) {
        this.value = this.input.nativeElement.value;
        const selectionStart = this.input.nativeElement.selectionStart;
        const selectionEnd = this.input.nativeElement.selectionEnd;
        const isSelected = (selectionStart - selectionEnd) > 0;
        const newValue = Number([this.value.slice(0, selectionStart), e.key, this.value.slice(selectionEnd)].join(''));

        if ([46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
            // Allow: Ctrl+A
            (e.keyCode === 65 && e.ctrlKey === true) ||
            // Allow: Ctrl+C
            (e.keyCode === 67 && e.ctrlKey === true) ||
            // Allow: Ctrl+X
            (e.keyCode === 88 && e.ctrlKey === true) ||
            // Allow: Ctrl+V
            (e.keyCode === 86 && e.ctrlKey === true) ||
            // Allow: Ctrl+Z
            (e.keyCode === 90 && e.ctrlKey === true) ||
            // Allow: Command+A
            (e.keyCode === 65 && e.metaKey === true) ||
            // Allow: Command+C
            (e.keyCode === 67 && e.metaKey === true) ||
            // Allow: Command+X
            (e.keyCode === 88 && e.metaKey === true) ||
            // Allow: Command+V
            (e.keyCode === 86 && e.metaKey === true) ||
            // Allow: Ctrl+Z
            (e.keyCode === 90 && e.metaKey === true) ||
            // Allow: home, end, left, right
            (e.keyCode >= 35 && e.keyCode <= 39)) {
            // let it happen, don't do anything
            return;
        }
        
        // Ensure that it is a number or decimal
        if (isNaN(newValue)) {
            e.preventDefault();
        }


        if ((e.key === '.' || e.key === ',')) {
            // Prevent if decimal disabled
            if (!this.decimal) {
                return e.preventDefault();
            }

            // Prevent double decimals
            if (this.value.indexOf('.') >= 0) {
                return e.preventDefault();
            }

            // Prevent inserting at start
            if (selectionStart === 0) {
                return e.preventDefault();
            }

            // Prevent decimal without value
            if (!this.value) {
                return e.preventDefault();
            }
        }

        if (e.key === '0') {
            // Prevent inserting at start
            if (this.value && selectionStart === 0) {
                e.preventDefault();
            }
            // Prevent start from 0 if decimal
            if (this.decimal === 0 && !this.value) {
                e.preventDefault();
            }
        }

        // replace if number starts from 0 , or .
        if (this.value === '0' && e.key !== '.' && e.key !== ',') {
            this.input.nativeElement.value = '';
        }


        // stop if decimal more then defined
        if (this.value.indexOf('.') > 0 && (this.decimal < this.value.length - this.value.indexOf('.')) && !isSelected) {
            // allow type before decimal
            if (selectionStart > this.value.indexOf('.') || (this.value.indexOf(0) === 0 && selectionStart !== 0)) {
                e.preventDefault();
            }
        }

        // limit max value
        if (newValue > this.max) {
            e.preventDefault();

            if (this.correction === true) {
                this.input.nativeElement.value = this.max;
            }
        }

        // Stop if maxLength reached
        if (this.value.replace('.', '').length >= this.maxLength && !isSelected) {
            return e.preventDefault();
        }

        // replace "," to "."
        if (e.key === ',') {
            this.input.nativeElement.value = this.insert(this.value, selectionStart, '.');
            e.preventDefault();
        }
    }

    @HostListener('keyup', ['$event']) onKeyup(e) {
        // prepend 0 if value starts with "."
        if (this.input.nativeElement.value.indexOf('.') === 0) {
            this.input.nativeElement.value = parseFloat(this.input.nativeElement.value);
        }
        // remove 0 if value starts with "0"
        if (this.input.nativeElement.value.indexOf('0') === 0) {
            this.input.nativeElement.value = parseFloat(this.input.nativeElement.value);
        }
    }


    insert(str, index, value) {
        return str.substr(0, index) + value + str.substr(index);
    }

    @HostListener('paste', ['$event']) onPaste(e) {
        e.preventDefault();
    }
}