import { Article } from '../../articles/_models/article.model';

export class CartItem {
    article: Article;
    errors: CartItemError[] = [];
    quantity: number;

    constructor(item: any) {
        this.article = new Article(item.article);
        this.quantity = item.quantity;
        if (item.errors) {
            this.errors = item.errors.map(error => new CartItemError(error));
        }
    }

    short() {
        return {
            article: this.article.id,
            quantity: this.quantity
        };
    }
}

export class CartItemError {
    code: string;
    message: string;

    constructor(data) {
        this.code = data.code;
        this.message = data.message;
    }
}