import { Injectable } from '@angular/core';
import { Language } from '../_models/language.model';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../core/_services/api.service';
import { AuthService } from '../../auth/_services/auth.service';
import { Subject } from 'rxjs';

@Injectable()
export class LanguagesService {
    selected: Language;
    onChange = new Subject<Language>();
    languages: Language[] = [
        new Language('nl', 'nl_NL', 'Dutch'),
        new Language('nl-be', 'nl_BE', 'Flemish Dutch'),
        new Language('fr', 'fr_BE', 'French')
    ];

    constructor(private $translate: TranslateService,
                private $api: ApiService,
                private $auth: AuthService) {
        this.initLanguage();
    }

    initLanguage() {
        let storedLanguageCode = localStorage.getItem('nutriciaAppLanguage');
        if (storedLanguageCode) {
            this.setLanguage(storedLanguageCode);
        } else {
            this.setDefault();
        }
    }

    setDefault() {
        const defaultLanguge = 'nl';
        this.$translate.setDefaultLang(defaultLanguge);
        this.setLanguage(defaultLanguge);
    }

    setLanguage(languageCode: string) {
        this.$translate.use(languageCode);
        this.selected = this.getLanguageByCode(languageCode);
        this.$api.setLanguage(this.selected.fullCode);
        this.updateCustomerLanguage();
        localStorage.setItem('nutriciaAppLanguage', languageCode);
        this.onChange.next(this.selected);
    }

    updateCustomerLanguage() {
        if (this.$auth.user) {
            let userId = this.$auth.user.id;
            this.$api.put(`api/customers/${userId}`, {'language': this.selected.fullCode}).catch(err => {
                this.setDefault();
            });
        }
    }

    getLanguageByCode(languageCode: string): Language {
        return this.languages.find(language => language.code === languageCode);
    }
}
