<header class="header">
    <a class="header_logo" href="/">
        <img src="assets/img/logo.png" alt="">
    </a>
    <h1 class="header_title">{{title | translate}}</h1>
    <ul class="header-controls" [ngClass]="{'-active': submenuOpened}">
        <ng-container *ngIf="authenticated">
            <li>
                <span>{{'welcome' | translate | titlecase}} ({{user.username}})</span>
            </li>
            <li>
                <button class="btn-pure" (click)="logout()">
                    {{'logout' | translate }}
                </button>
            </li>
        </ng-container>
        <li>
            <app-language-switcher></app-language-switcher>
        </li>
    </ul>
    <span class="hamburger" (click)="toggleSubmenu()" [ngClass]="{'-active': submenuOpened}">
        <span class="hamburger_box">
            <span class="hamburger_inner"></span>
        </span>
    </span>
</header>
