import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { ArticlesOverviewComponent } from './articles/articles-overview/articles-overview.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { CheckoutGuard } from './checkout/_services/checkout.guard';
import { AuthGuard } from './auth/_services/auth.guard';
import { UnauthGuard } from './auth/_services/unauth.guard';
import { TermsConditionsResolver } from './checkout/_services/terms-conditions.resolver';

const appRoutes: Routes = [
    {
        path: '',
        component: ArticlesOverviewComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [UnauthGuard]
    },
    {
        path: 'checkout',
        component: CheckoutComponent,
        canActivate: [AuthGuard, CheckoutGuard],
        resolve: {
            text: TermsConditionsResolver
        }
    }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})

export class AppRoutingModule {

}
