export class ArticleStockNotifications {
    articles: { article: number }[] = [];

    constructor(data: any[]) {
        if (data) {
            this.articles = data.map((item) => {
                return {article: item.article.id};
            });
        }
    }

    addArticle(articleId: number) {
        this.articles.push(
            {article: articleId}
        );
    }

    removeArticle(articleId: number) {
        this.articles = this.articles.filter((item) => {
            return item.article !== articleId;
        });
    }
}