import { Injectable } from '@angular/core';
import { Article } from '../_models/article.model';
import { CartService } from '../../cart/_services/cart.service';
import { CartItem } from '../../cart/_models/cart-item.model';
import { ApiService } from '../../core/_services/api.service';
import { ArticleGroup } from '../_models/article-group.model';
import { AuthService } from '../../auth/_services/auth.service';
import { ArticleStockNotifications } from '../_models/article-stock-notifications.model';

@Injectable()
export class ArticlesService {
    articles: Article[] = [];
    articleGroups: ArticleGroup[] = [];
    stockNotifications: ArticleStockNotifications = new ArticleStockNotifications([]);

    constructor(private $cart: CartService,
                private $auth: AuthService,
                private $api: ApiService) {

        this.cartSubscription();
        this.getArticleGroups();
        this.setStockNotifications();
    }

    setStockNotifications() {
        this.stockNotifications = new ArticleStockNotifications(this.$auth.user.articleStockNotifications);
    }

    updateStockNotifications(articleId: number, enableNotification: boolean) {
        if (enableNotification) {
            this.stockNotifications.addArticle(articleId);
        } else {
            this.stockNotifications.removeArticle(articleId);
        }

        this.$api.put(`api/customers/${this.$auth.user.id}/article_stock_notifications`, this.stockNotifications).then((user: any) => {
            this.$auth.user = user;
        });
    }

    getArticleGroups() {
        return new Promise((resolve) => {
            if (this.articleGroups.length) {
                resolve(this.articleGroups);
            } else {
                return this.$api.get('api/article_groups').then((articleGroups: any[]) => {
                    this.articleGroups = articleGroups.map(item => new ArticleGroup(item));
                    resolve(this.articleGroups);
                }).catch(err => {
                    resolve([]);
                });
            }
        });
    }

    setArticles(params) {
        this.$api.get('api/articles', params).then((articles: any[]) => {
            this.articles = articles.map(item => new Article(item));
            this.syncArticlesQuantityWithCart();
            this.syncArticlesWithStockNotifications();
        });
    }


    cartSubscription() {
        this.$cart.onCartItemRemoved.subscribe((cartItem: CartItem) => {
            if (this.articles.length) {
                this.updateArticleQuantity(cartItem);
            }
        });
    }

    syncArticlesWithStockNotifications() {
        this.articles.forEach((article: Article) => {
            this.stockNotifications.articles.forEach((stockNotification) => {
                if (article.id === stockNotification.article) {
                    article.stockNotification = true;
                }
            });
        });
    }

    syncArticlesQuantityWithCart() {
        this.articles.forEach((article: Article) => {
            this.$cart.items.forEach((cartItem: CartItem) => {
                if (cartItem.article.id === article.id) {
                    article.stock -= cartItem.quantity;
                }
            });
        });
    }

    updateArticleQuantity(cartItem: CartItem) {
        const removedArticle = this.articles.find(article => article.id === cartItem.article.id);
        if (removedArticle) {
            removedArticle.stock = cartItem.article.stock;
        }
    }
}
