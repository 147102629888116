import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../../core/_services/api.service';

@Injectable()
export class TermsConditionsResolver implements Resolve<any> {

    constructor(private $api: ApiService, private router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return this.$api.get(`api/texts`).then((texts: any) => {
            return texts.find(text => text.code === 'terms').content;
        }).catch(err => {
            this.router.navigate(['/']);
        });
    }
}
