import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class NotificationService {
    constructor(
        private toastr: ToastrService,
        private translate: TranslateService
    ) {}

    success(text) {
        this.toastr.success(text);
    }

    error(text, title = null, config = null) {
        this.toastr.error(text, title, config);
    }

    serverError() {
        this.toastr.error(
            this.translate.instant("errors.went_wrong"),
            this.translate.instant("errors.server_communication")
        );
    }

    sessionExpired() {
        this.toastr.error(
            this.translate.instant("errors.session_expired"),
            this.translate.instant("errors.unauthorized_error")
        );
    }
}
