<div class="article">
    <div class="article_image mr-1" [style.background-image]="'url(' + article.thumbnail + ')'">
        <ks-modal-gallery [id]="article.id" [modalImages]="[image]"
                          [currentImageConfig]="{description: imageDescription}"
                          [slideConfig]="{infinite: false, sidePreviews: {show: false}}"
                          [buttonsConfig]="{visible: false, strategy: 1}"
                          [previewConfig]="{visible: false}"
                          [dotsConfig]="{visible: false}"></ks-modal-gallery>
    </div>
    <div class="article_details">
        <p class="article_name">{{article.name}}</p>
        <p>Packing unit: {{article.packingUnit}}</p>
        <p>{{article.articleGroup.name}}</p>
        <p>{{article.fullCode}}</p>
    </div>
    <div class="article-controls" *ngIf="article.stock > 0">
        <div class="v-center">
            <input appNumber
                   [max]="article.stock"
                   [maxLength]="5"
                   [decimal]="0"
                   class="input mr-1"
                   type="text"
                   [(ngModel)]="quantity">
            <button [disabled]="additionDisabled()" class="btn-pure" (click)="addToCart()">
                <img src="assets/img/cart-add.svg" alt="">
            </button>
        </div>
        <small>{{'stock' | translate}}: {{article.stock}}</small>
    </div>
    <div class="article-controls" *ngIf="article.stock < 1">
        <div class="flex-column v-end">
            <mat-checkbox [checked]="article.stockNotification" (change)="subscribeNotification($event)">
                {{'mail_stock' | translate}}
            </mat-checkbox>
            <small class="mt-2">{{'not_on_stock' | translate}}</small>
        </div>
    </div>
</div>
